import { ChangeEvent, useState } from 'react';

import { CircularProgress } from '@mui/material';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import { Organisation } from '../../../store/OrganisationStore';
import { ROLES } from '../../../utils/constants';
import { useAsyncFetcher } from '../../../utils/useAsyncFetcher';
import { Button } from '../../basic/Button.component';
import HorizontalSeparator from '../../basic/HorizontalSeparator';
import { useConfirm } from '../../Confirm';
import { useServices } from '../../ServiceProvider';
import OrganisationLogo from '../createOrganisation/OrganisationLogo.component';
import OrganistaionForm from '../createOrganisation/OrganistaionForm.component';

const Settings = () => {
    const confirm = useConfirm();
    const history = useHistory();
    const { id: organisationId } = useParams<{ id: string }>();
    const { organisation } = useServices();
    const { data, isLoading } = useQuery<Organisation>(['getOrganisation', organisationId], async () => {
        try {
            return await organisation.getOrganisation(organisationId);
        } catch (e) {
            console.error(e);
        }
    });
    const [file, setFile] = useState<File | null>(null);
    const [deleteOrgLoading, setDeleteOrgLoading] = useState(false);
    if (organisationId) localStorage.setItem('spaceId', organisationId);

    if (isLoading || deleteOrgLoading)
        return (
            <div className='flex-grow flex flex-col justify-center items-center pt-20 text-darker text-opacity-75 mt-24'>
                <CircularProgress sx={{ color: '#373BBA', width: '32px', height: '32px' }} />
            </div>
        );
    
    if (!data) {
        history.replace('/home');
        return null;
    }
    const handleUpload = (e: ChangeEvent<HTMLInputElement>) => setFile(e.target.files ? e.target.files[0] : null);

    const handleDeleteOrganisation = async () => {
        const confirmed = await confirm({
            body: (
                <div className='space-y-2 text-xs text-darker text-opacity-75'>
                    <p>All data associated with the organisation will be permanently deleted. </p>
                    <p>For more information on how Traceflow manages your data, please review our Privacy Policy.</p>
                </div>
            ),
            warning: (
                <div className='delete-warning-modal mt-5 text-xs text-darker text-opacity-75'>
                    <div className='flex space-x-2 items-center font-medium'>
                        <p>Warning: This ation cannot be undone.</p>
                    </div>
                    <div className='mt-2 font-normal'>
                        <p>Once you delete the organization, you will no longer be able to:</p>
                        <ul className='mt-4 with-bullets'>
                            <li>Access the organisation and it’s data</li>
                            <li>Recover any projects or metrics</li>
                        </ul>
                    </div>
                </div>
            ),
            yesLabel: 'Yes, delete organization',
            noLabel: 'Cancel',
            yesColor: 'rejectRed',
            title: `Sorry to see you go!`,
            enableDeleteInput: true,
        });
        if (confirmed) {
            try {
                setDeleteOrgLoading(true);
                const result = await organisation.deleteOrganisation(organisationId);
                if (result) {
                    localStorage.removeItem('spaceId');
                    history.replace('/home');
                    window.location.reload();
                }
            } catch (e) {
                setDeleteOrgLoading(false);
                console.error(e);
            }
        }
    };

    return (
        <div className='w-full h-full max-w-5xl mx-auto space-y-8 mt-5'>
            <OrganisationLogo onChange={handleUpload} documnetId={data?.logo} />
            <HorizontalSeparator />
            <OrganistaionForm settings initialValues={data} file={file} />
            {data?.role === ROLES.Global && (
                <div className='w-full space-y-4 mb-4'>
                    <HorizontalSeparator />
                    <div className='flex justify-between items-center'>
                        <div className='text-darker text-opacity-75 text-sm space-y-1'>
                            <p className='font-medium'>Delete this Organization</p>
                            <p className='font-normal'>
                                Deleting organization is irreversible; all members and data will be lost
                            </p>
                        </div>
                        <Button
                            size='oval'
                            className='flex space-x-2 py-5 h-10 items-center'
                            variant='rejectRed'
                            onClick={handleDeleteOrganisation}
                            disabled={deleteOrgLoading}
                        >
                            Delete Organization
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Settings;
