import { makeStyles } from '@material-ui/core';

import { ReactComponent as EditIcon } from '../../../../../assets/icons/icon-edit-fill.svg';
import { evaTitleFieldStyle } from './CreateEva.components';
import { StyledTextField } from './createEva/StyledTextField';

const useStyles = makeStyles(() => ({
    evaField: {
        borderRadius: 7,
        backgroundColor: 'transparent',
        width: "26.042vw",
        '& .MuiInput-underline:before, & .MuiInput-underline:after': {
            display: 'none',
        },
        '& .MuiInput-underline input': {
            textAlign: 'center',
        },
        '& .MuiInputBase-input::placeholder': {
            opacity: 0.5,
            textAlign: 'center',
        },
    },
}));

const EvaTitleDescription = ({
    input,
    size,
    setInput,
    handleValidation,
    type,
    maxLength,
    font,
    fontWeight,
    edit,
    toggleEdit,
    className,
}: {
    input: string;
    size: string;
    setInput: (value: string) => void;
    handleValidation: (field: string, value: string) => void;
    type: string;
    maxLength: number;
    font: 'small' | 'medium' | 'large';
    fontWeight: 'normal' | 'medium' | 'semibold' | 'bold' | 'black';
    edit: boolean;
    className: string;
    toggleEdit: () => void;
}) => {
    const classes = useStyles();
    return (
        <div className={`${className} w-full`}>
            {!edit ? (
                <div className='flex space-x-2 items-center justify-center group relative'>
                    <p className='flex text-center text-white font-medium relative'>
                        <p className={`text-${size} `}>{input}</p>
                        <span className='text-error text-sm'>*</span>
                    </p>
                    <button
                        className='mb-1 flex items-center justify-center rounded-full  bg-opacity-10  group-hover:opacity-100 opacity-0'
                        onClick={toggleEdit}
                    >
                        <div>
                            <EditIcon className='w-2.5 h-2.5' fill='#FFFF' />
                        </div>
                    </button>
                </div>
            ) : (
                <div className='w-full flex justify-center items-center mb-1'>
                    <StyledTextField
                        id={`metaMarker-${type}`}
                        className={`${classes.evaField}`}
                        font={font}
                        fontWeight={fontWeight}
                        placeHolder={''}
                        value={input}
                        setValue={setInput}
                        maxLength={maxLength}
                        onBlur={() => handleValidation(type, input)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                toggleEdit();
                                handleValidation(type, input);
                            }
                        }}
                        style={evaTitleFieldStyle}
                        showRequiredStar={false}
                    />
                </div>
            )}
        </div>
    );
};

export default EvaTitleDescription;
