import React, { useEffect, useState, useRef, RefObject } from 'react';

import { Circle } from '@mui/icons-material';
import { Link, useParams } from 'react-router-dom';

import { ReactComponent as FlagIcon } from '../../../../../../assets/icons/icon-metamarker-trigger-flag.svg';
import { AickoCurrentSubscription } from '../../../../../../store/SubscriptionsStore';
import { StarIcon } from '../../../../../basic/StarIcon.component';
import { Nuances, SubScriptionType } from '../../types';
import { getRgbColorCode } from '../ColorCode';
import { ReactComponent as PlusCircleIcon } from '../img/plus-icon-new.svg';

interface informationBoxProps {
    metaMarker: string;
    nuances: number;
    triggerState: string;
    triggerNumber: number;
}

const InformationBox = ({ metaMarker, nuances, triggerState, triggerNumber }: informationBoxProps) => {
    return (
        <>
            <p className='boxHeading'>{metaMarker}</p>
            <hr className='boxBorder' />
            <div className='boxNucences'>
                {nuances} <span className='boxNucenceText'>Nuances</span>
            </div>
            <div className='boxTrigger'>
                <FlagIcon />
                <span>{triggerState}</span>
                <span> {triggerNumber}</span>
            </div>
        </>
    );
};

interface CreateEvaOrbitProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    metaMarker: any[];
    handleDlClick: (event: React.MouseEvent<HTMLElement>, index?: number | null) => void;
    createMarkerOpen: boolean;
    setMarkerIndex: (value: number | null) => void;
    currentSlide: number;
    subscription: AickoCurrentSubscription | null;
    labelShow: boolean;
    markerIndex: number | null;
    evaluateMode: boolean;
    setHoldSidebar: React.Dispatch<React.SetStateAction<boolean>>;
    protocolIndex?: number | null;
}

export const CreateEvaOrbit = ({
    metaMarker,
    handleDlClick,
    createMarkerOpen,
    setMarkerIndex,
    currentSlide,
    subscription,
    labelShow,
    markerIndex,
    evaluateMode,
    setHoldSidebar,
    protocolIndex,
}: CreateEvaOrbitProps) => {
    const [showBoxOnHover, setShowBoxOnHover] = useState(false);
    const [currentIndex, setCurrentIndex] = useState<number | null>(null);
    const [subCurrentIndex, setSubCurrentIndex] = useState<number | null>(null);
    const [maxOrbitMarker, setMaxOrbitMarker] = useState<number | null>(null);
    const [dotHoverIndex, setDotHoverIndex] = useState<number | null>(null);
    const [oneOrbitIndex, setOneOrbitIndex] = useState<number | null>(null);
    const [showOneOrbit, setShowOneOrbit] = useState(false);

    useEffect(() => {
        if (!createMarkerOpen) {
            setDotHoverIndex(null);
        }

        if (subCurrentIndex !== null) {
            setHoldSidebar(true);
        } else {
            setHoldSidebar(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createMarkerOpen, subCurrentIndex]);

    useEffect(() => {
        if (markerIndex !== null || protocolIndex !== null) {
            setShowBoxOnHover(true);

            const activeIndex = markerIndex ?? protocolIndex ?? 0;
            setCurrentIndex(Math.floor(activeIndex / 10));

            setOneOrbitIndex(Math.floor((markerIndex ?? protocolIndex ?? 0) / 10));
        }
    }, [markerIndex, protocolIndex]);

    const { id: organisationId } = useParams<{ id: string }>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const orbitItems: any = {
        1: {
            top: '40%',
            left: '-2.9%',
        },
        2: {
            top: '6%',
            left: '11%',
        },
        3: {
            top: '-6%',
            left: '30%',
        },
        4: {
            top: '-4%',
            left: '72%',
        },
        5: {
            top: '6%',
            left: '85%',
        },
        6: {
            top: '40%',
            left: '98%',
        },
        7: {
            top: '75%',
            left: '85%',
        },
        8: {
            top: '88%',
            left: '65%',
        },
        9: {
            top: '84%',
            left: '23%',
        },
        10: {
            top: '74%',
            left: '10%',
        },
    };

    const isShieldSubscription = subscription?.productName === SubScriptionType.SHIELD;
    const isOversightSubscription = subscription?.productName === SubScriptionType.OVERSIGHT;

    useEffect(() => {
        if (subscription) {
            if (isShieldSubscription) {
                setMaxOrbitMarker(10);
            } else if (isOversightSubscription) {
                setMaxOrbitMarker(40);
            } else {
                setMaxOrbitMarker(40);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscription]);

    useEffect(() => {
        if (metaMarker.length <= 9) {
            setShowBoxOnHover(true);
            setShowOneOrbit(true);
            setOneOrbitIndex(0);
            setCurrentIndex(0);
        } else {
            setShowBoxOnHover(false);
            setShowOneOrbit(false);
        }

        if (labelShow && createMarkerOpen) {
            setShowBoxOnHover(true);
            setShowOneOrbit(true);
            setOneOrbitIndex(0);
        } else {
            setShowBoxOnHover(false);
            setShowOneOrbit(false);
        }
    }, [metaMarker, createMarkerOpen, labelShow]);

    const id = createMarkerOpen ? 'simple-popper' : undefined;

    const MAX_MARKERS_PER_ORBIT = 10;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const orbits: any = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let currentOrbit: any = [];

    // Function to push markers into the current orbit
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const pushMarkerToOrbit = (marker: any, index: number) => {
        if (currentOrbit.length < MAX_MARKERS_PER_ORBIT) {
            currentOrbit.push({ ...marker, key: index });
        } else {
            orbits.push([...currentOrbit]);
            currentOrbit = [{ ...marker, key: index }];
        }
    };

    // Iterate through metaMarker to populate orbits
    metaMarker.forEach((marker, index) => {
        pushMarkerToOrbit(marker, index);
        // For the last marker, push the remaining markers in currentOrbit to orbits
        if (index === metaMarker.length - 1) {
            orbits.push([...currentOrbit]);
            if (isOversightSubscription && metaMarker.length !== 40) {
                if (currentOrbit.length === MAX_MARKERS_PER_ORBIT) {
                    // Create a new orbit if the last orbit is full
                    orbits.push([]);
                }
            } else if (isShieldSubscription && metaMarker.length === 10) {
                orbits.push([]);
            }
        }
    });

    // eslint-disable-next-line prefer-const
    let totalOrbits = Math.ceil(
        (metaMarker.length === maxOrbitMarker ? metaMarker.length : metaMarker.length + 1) / MAX_MARKERS_PER_ORBIT,
    );

    if (subscription && subscription.productName === SubScriptionType.SHIELD) {
        totalOrbits = totalOrbits + 1;
    }

    const rotationAngle = 180 / totalOrbits;

    const orbitRefs = useRef<Array<RefObject<HTMLDivElement>>>(
        new Array(orbits.length).fill(null).map(() => React.createRef()),
    );

    const orbitsJSX = metaMarker.length ? (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        orbits.map((orbit: any, i: number) => (
            <div
                key={`orbit-${i}`}
                // onClick={() => setCurrentIndex(i)}

                ref={orbitRefs.current[i]}
                className={`orbit ${
                    createMarkerOpen ? (i === currentIndex ? 'enabled' : 'disabled') : ''
                } cursor-pointer`}
                style={{
                    transform: `translate(-50%,-50%)rotate(${i * rotationAngle}deg)`,
                }}
            >
                {evaluateMode ? (
                    <div className='wrapper' />
                ) : (
                    <div className='wrapper' onClick={(e) => e.stopPropagation()} />
                )}
                <>
                    {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        orbit.map((marker: any, j: number) => {
                            let color = '#4A95D6';
                            if (marker.color !== '' && marker.color !== undefined) {
                                color = marker.color;
                            } else {
                                if (metaMarker[j].nuances && metaMarker[j].nuances.length > 0) {
                                    // Calculate the average threshold for nuances
                                    color = getRgbColorCode(metaMarker[j].nuances.map((ob: Nuances) => ob.threshold));
                                }
                            }

                            //   protocol list hover and orbit hover
                            const showPopupBox =
                                protocolIndex !== null ? protocolIndex === marker.key : subCurrentIndex === marker.key;

                            const showHoverBox =
                                (showBoxOnHover && currentIndex === i && showPopupBox) ||
                                (markerIndex === i && showPopupBox);

                            const isHighlighted =
                                (createMarkerOpen || showBoxOnHover || showOneOrbit) &&
                                i === (currentIndex || oneOrbitIndex);

                            const backgroundColor = showHoverBox
                                ? '#6FBFE1'
                                : isHighlighted || labelShow
                                ? '#6FBFE180'
                                : '';
                            const display = showHoverBox ? 'block' : isHighlighted || labelShow ? 'block' : 'none';

                            const circleStyle: React.CSSProperties = {
                            
                                cursor: currentSlide === 0 ? '' : 'pointer',
                                fill: color,
                            };

                            const lineStyle: React.CSSProperties = {
                                backgroundColor: backgroundColor,
                                width: ' 6.771vw',
                                display: `${display}`,
                            };

                            const metaMarkerValue =
                                ((createMarkerOpen || showBoxOnHover || showOneOrbit) &&
                                    i === (currentIndex || oneOrbitIndex)) ||
                                labelShow
                                    ? marker.metaMarker
                                    : '';

                            const hideContentOnHover = showPopupBox && showBoxOnHover;

                            const existingStyle = orbitItems[j + 1];
                            const newStyle = {
                                ...existingStyle,
                                transform: `rotate(${-(i * rotationAngle)}deg)`,
                            };

                            return (
                                <div key={`marker-${i}-${j}`} className='pos' id='pos_box' style={newStyle}>
                                    <div
                                        className='absolute circle_outer'
                                        onClick={(e) => {
                                            setMarkerIndex(marker.key);
                                            handleDlClick(e, marker.key);
                                            setDotHoverIndex(marker.key);
                                            setCurrentIndex(i);
                                        }}
                                    >
                                        <Circle
                                            onMouseOver={(e) => {
                                                setShowBoxOnHover(true);
                                                setCurrentIndex(i);
                                                setSubCurrentIndex(marker.key);
                                            }}
                                            style={circleStyle}
                                            className={`${
                                                (currentIndex === i && dotHoverIndex === marker.key) ||
                                                markerIndex === marker.key ||
                                                (currentIndex === i && showPopupBox)
                                                    ? 'current_dot_hover'
                                                    : ''
                                            } dot_hover`}
                                            onMouseLeave={() => {
                                                setShowBoxOnHover(false);
                                                setSubCurrentIndex(null);
                                            }}
                                        />
                                    </div>

                                    {i === 0
                                        ? j >= 3 && j <= 7
                                            ? showHoverBox && (
                                                  <div
                                                      className='absolute left-1/2 origin-left top-100  boxStyle'
                                                      onMouseLeave={() => {
                                                          setShowBoxOnHover(false);
                                                          setSubCurrentIndex(null);
                                                      }}
                                                  >
                                                      <InformationBox
                                                          metaMarker={marker.metaMarker}
                                                          nuances={marker.nuances.length}
                                                          triggerState={marker.triggerState}
                                                          triggerNumber={marker.triggerNumber}
                                                      />
                                                  </div>
                                              )
                                            : showHoverBox && (
                                                  <div className='absolute left-130 origin-right left-100  boxStyle'>
                                                      <InformationBox
                                                          metaMarker={marker.metaMarker}
                                                          nuances={marker.nuances.length}
                                                          triggerState={marker.triggerState}
                                                          triggerNumber={marker.triggerNumber}
                                                      />
                                                  </div>
                                              )
                                        : j >= 1 && j <= 4
                                        ? showHoverBox && (
                                              <div
                                                  className=' absolute origin-left top-100  boxStyle'
                                                  onMouseLeave={() => {
                                                      setShowBoxOnHover(false);
                                                      setSubCurrentIndex(null);
                                                  }}
                                              >
                                                  <InformationBox
                                                      metaMarker={marker.metaMarker}
                                                      nuances={marker.nuances.length}
                                                      triggerState={marker.triggerState}
                                                      triggerNumber={marker.triggerNumber}
                                                  />
                                              </div>
                                          )
                                        : showHoverBox && (
                                              <div
                                                  className=' absolute origin-right top-1/2 left-100  boxStyle'
                                                  onMouseLeave={() => {
                                                      setShowBoxOnHover(false);
                                                      setSubCurrentIndex(null);
                                                  }}
                                              >
                                                  <InformationBox
                                                      metaMarker={marker.metaMarker}
                                                      nuances={marker.nuances.length}
                                                      triggerState={marker.triggerState}
                                                      triggerNumber={marker.triggerNumber}
                                                  />
                                              </div>
                                          )}

                                    {i === 0 ? (
                                        j >= 3 && j <= 7 ? (
                                            <div
                                                className={`${
                                                    (currentIndex === i && dotHoverIndex === marker.key) ||
                                                    markerIndex === marker.key ||
                                                    (currentIndex === i && subCurrentIndex === marker.key)
                                                        ? evaluateMode && 'current_line'
                                                        : ''
                                                } absolute top-1/2 h-px origin-left left-1/2 LineBg`}
                                                style={lineStyle}
                                            >
                                                <div
                                                    className={` absolute right-0   metaMakerText origin-left text-end ${
                                                        subCurrentIndex == marker.key ? 'show_popup' : ''
                                                    }`}
                                                    onMouseOver={(e) => {
                                                        setShowBoxOnHover(true);
                                                        setCurrentIndex(i);
                                                        setSubCurrentIndex(marker.key);
                                                    }}
                                                    onClick={(e) => {
                                                        setMarkerIndex(marker.key);
                                                        handleDlClick(e, marker.key);
                                                    }}
                                                    onMouseLeave={() => {
                                                        setShowBoxOnHover(false);
                                                        setSubCurrentIndex(null);
                                                    }}
                                                />
                                                <>
                                                    <div
                                                        className={`${
                                                            (currentIndex === i && dotHoverIndex === marker.key) ||
                                                            markerIndex === marker.key ||
                                                            (currentIndex === i && subCurrentIndex === marker.key)
                                                                ? evaluateMode && 'current_text'
                                                                : ''
                                                        } fs-8 metaMaker_text right_marker absolute right-0 whitespace-nowrap bottom-2  origin-left text-end`}
                                                    >
                                                        {!hideContentOnHover && metaMarkerValue}

                                                        <div className={`${'protocol_' + (marker.key + 1)} square`} />
                                                    </div>
                                                </>
                                            </div>
                                        ) : (
                                            <div
                                                className={`${
                                                    (currentIndex === i && dotHoverIndex === marker.key) ||
                                                    markerIndex === marker.key ||
                                                    (currentIndex === i && subCurrentIndex === marker.key)
                                                        ? evaluateMode && 'current_line'
                                                        : ''
                                                } absolute origin-right top-1/2 h-px  left-130 left-1/2 LineBg`}
                                                style={lineStyle}
                                            >
                                                <div
                                                    className={`absolute left-0 bottom-2 origin-right metaMakerText ${
                                                        subCurrentIndex == marker.key ? 'show_popup' : ''
                                                    }`}
                                                    onMouseOver={(e) => {
                                                        setShowBoxOnHover(true);
                                                        setCurrentIndex(i);
                                                        setSubCurrentIndex(marker.key);
                                                    }}
                                                    onClick={(e) => {
                                                        setMarkerIndex(marker.key);
                                                        handleDlClick(e, marker.key);
                                                    }}
                                                    onMouseLeave={() => {
                                                        setShowBoxOnHover(false);
                                                        setSubCurrentIndex(null);
                                                    }}
                                                />
                                                <div
                                                    className={`${
                                                        (currentIndex === i && dotHoverIndex === marker.key) ||
                                                        markerIndex === marker.key ||
                                                        (currentIndex === i && subCurrentIndex === marker.key)
                                                            ? evaluateMode && 'current_text'
                                                            : ''
                                                    } fs-8  metaMaker_text  left_marker whitespace-nowrap absolute left-0 bottom-2 origin-right`}
                                                >
                                                    {!hideContentOnHover && metaMarkerValue}

                                                    <div className={`${'protocol_' + (marker.key + 1)} square`} />
                                                </div>
                                            </div>
                                        )
                                    ) : j >= 1 && j <= 4 ? (
                                        <div
                                            className={`${
                                                (currentIndex === i && dotHoverIndex === marker.key) ||
                                                markerIndex === marker.key ||
                                                (currentIndex === i && subCurrentIndex === marker.key)
                                                    ? evaluateMode && 'current_line'
                                                    : ''
                                            } absolute top-1/2 h-px origin-left left-1/2 LineBg`}
                                            style={lineStyle}
                                        >
                                            <div
                                                className={`absolute right-0   origin-left metaMakerText ${
                                                    subCurrentIndex == marker.key ? 'show_popup' : ''
                                                }`}
                                                onMouseOver={(e) => {
                                                    setShowBoxOnHover(true);
                                                    setCurrentIndex(i);
                                                    setSubCurrentIndex(marker.key);
                                                }}
                                                onClick={(e) => {
                                                    setMarkerIndex(marker.key);
                                                    handleDlClick(e, marker.key);
                                                }}
                                                onMouseLeave={() => {
                                                    setShowBoxOnHover(false);
                                                    setSubCurrentIndex(null);
                                                }}
                                            />
                                            <div
                                                className={`${
                                                    (currentIndex === i && dotHoverIndex === marker.key) ||
                                                    markerIndex === marker.key ||
                                                    (currentIndex === i && subCurrentIndex === marker.key)
                                                        ? evaluateMode && 'current_text'
                                                        : ''
                                                } fs-8  text-end whitespace-nowrap text-right right_marker  absolute right-0   origin-left   metaMaker_text`}
                                            >
                                                {!hideContentOnHover && metaMarkerValue}

                                                <div className={`${'protocol_' + (marker.key + 1)} square`} />
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={`${
                                                (currentIndex === i && dotHoverIndex === marker.key) ||
                                                markerIndex === marker.key ||
                                                (currentIndex === i && subCurrentIndex === marker.key)
                                                    ? evaluateMode && 'current_line'
                                                    : ''
                                            } absolute origin-right top-1/2 h-px  left-130 left-1/2`}
                                            style={lineStyle}
                                        >
                                            <div
                                                className={`fs-8 absolute  left-0  origin-right  metaMakerText ${
                                                    subCurrentIndex == marker.key ? 'show_popup' : ''
                                                }`}
                                                onMouseOver={(e) => {
                                                    setShowBoxOnHover(true);
                                                    setCurrentIndex(i);
                                                    setSubCurrentIndex(marker.key);
                                                }}
                                                onClick={(e) => {
                                                    setMarkerIndex(marker.key);
                                                    handleDlClick(e, marker.key);
                                                }}
                                                onMouseLeave={() => {
                                                    setShowBoxOnHover(false);
                                                    setSubCurrentIndex(null);
                                                }}
                                            />
                                            <div
                                                className={`${
                                                    (currentIndex === i && dotHoverIndex === marker.key) ||
                                                    markerIndex === marker.key ||
                                                    (currentIndex === i && subCurrentIndex === marker.key)
                                                        ? evaluateMode && 'current_text'
                                                        : ''
                                                } fs-8 absolute  left-0  origin-right  left_marker  metaMaker_text whitespace-nowrap text-right`}
                                            >
                                                {!hideContentOnHover && metaMarkerValue}

                                                <div className={`${'protocol_' + (marker.key + 1)} square`} />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            );
                        })
                    }

                    {
                        <>
                            {' '}
                            {i === orbits.length - 1 &&
                                (isShieldSubscription && metaMarker.length >= 10 ? (
                                    <Link
                                        key={`plus-icon-${i}`}
                                        className='pos orbit_div'
                                        style={
                                            currentOrbit.length === 10
                                                ? orbitItems[1]
                                                : orbitItems[currentOrbit.length + 1]
                                        }
                                        to={`/organisation/${organisationId}/change-tier`}
                                    >
                                        {' '}
                                        <StarIcon isHover={true} />
                                    </Link>
                                ) : (
                                    <>
                                        {isOversightSubscription && metaMarker.length == 40 ? (
                                            ''
                                        ) : (
                                            <div
                                                key={`plus-icon-${i}`}
                                                className='pos'
                                                style={
                                                    currentOrbit.length === 10
                                                        ? orbitItems[1]
                                                        : orbitItems[currentOrbit.length + 1]
                                                }
                                                onClick={(e) => {
                                                    handleDlClick(e);
                                                    setCurrentIndex(i);
                                                }}
                                                aria-describedby={id}
                                            >
                                                <dd>
                                                    <div className='eva-plus-circle-icon-wrapper'>
                                                        <PlusCircleIcon />
                                                    </div>
                                                </dd>
                                            </div>
                                        )}
                                    </>
                                ))}
                        </>
                    }
                </>
            </div>
        ))
    ) : (
        <>
            <div key='orbit0' className='orbit'>
                <div
                    key='plus-icon0'
                    className='pos'
                    style={orbitItems[currentOrbit.length + 1]}
                    onClick={(e) => {
                        handleDlClick(e);
                    }}
                    aria-describedby={id}
                >
                    <dl className={`${createMarkerOpen && orbits.length === 0 && 'hide_orbit_line'} infos`}>
                        {!createMarkerOpen && orbits.length === 0 && (
                            <dt>
                                Create your first <br /> <span>Protocol</span>
                            </dt>
                        )}
                        <dd>
                            <div className='eva-plus-circle-icon-wrapper'>
                                <PlusCircleIcon />
                            </div>
                        </dd>
                    </dl>
                </div>
            </div>
        </>
    );

    return orbitsJSX;
};
