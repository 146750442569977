import { useRef, useState } from 'react';

import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useQuery } from 'react-query';

import { appInsights } from '../../../../../../AppInsights';
import { ReactComponent as BackIcon } from '../../../../../../assets/icons/icon-arrow-back.svg';
import { ReactComponent as DeleteIcon } from '../../../../../../assets/icons/icon-delete-fill.svg';
import { ReactComponent as EditIcon } from '../../../../../../assets/icons/icon-edit-fill.svg';
import { MetricService } from '../../../../../../services/metric/metric.service';
import { Button } from '../../../../../basic/Button.component';
import { Description } from '../../../../../basic/Description.component';
import HorizontalSeparator from '../../../../../basic/HorizontalSeparator';
import { Select } from '../../../../../basic/Select.component';
import { Switch } from '../../../../../basic/Switch.component';
import { Loading } from '../../../../../loading';
import { evaTitleFieldStyle } from '../CreateEva.components';
import { StyledTextField } from '../createEva/StyledTextField';
import EvaDialog from '../EvaDialog.component';
import {
    categoryList,
    getAggregationTypeList,
    getCalculationMethodList,
    getFormatListByCategory,
    getFrequencyList,
    getTimeFrameList,
    Metric,
    MetricCategoryType,
} from './metricsType';

const useStyles = makeStyles(() => ({
    metricTitle: (props: { error: boolean }) => ({
        backgroundColor: 'transparent',
        width: 140,
        overflow: 'hidden',
        color: 'rgba(111, 191, 225, 1)',
        '& .MuiInput-underline:before, & .MuiInput-underline:after': {
            display: (props) => (props.error ? 'block' : 'none'), // Change based on error state
            borderBottom: (props) => (props.error ? '1px solid #FF5E36' : 'none'), // Apply borderBottom on error
        },
        '& .MuiInput-underline input': {
            textAlign: 'start',
        },
        '& .MuiInputBase-input::placeholder': {
            opacity: 0.75,
            textAlign: 'start',
        },
    }),
}));

const CreateNewMetric = ({
    mode,
    closeMetric,
    evaId,
    metricId,
    updateMetric,
    deleteMetric,
}: {
    mode?: 'create' | 'update';
    closeMetric: () => void;
    evaId?: string;
    metricId?: string;
    updateMetric: (metricId: string, metricData: Partial<Metric>, mode: string) => void;
    deleteMetric: (metricId: string) => void;
}) => {
    const titleRef = useRef<HTMLDivElement>(null);
    const [metric, setMetric] = useState<Metric>({
        name: '',
        description: '',
        category: '',
        format: '',
        frequency: '',
        method: '',
        timeFrame: '',
        type: '',
        feedback: false,
    });
    const [editTitle, setEditTitle] = useState(true);
    const [count, setCount] = useState<number>(0);
    const [category, setCategory] = useState<MetricCategoryType | undefined>();
    const [error, setError] = useState({
        titleError: false,
        descriptionError: false,
        categoryError: false,
        formatError: false,
        frequencyError: false,
        methodError: false,
        timeFrameError: false,
        typeError: false,
    });
    const [loading, setLoading] = useState(false);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
    const maxLength = 22;
    const classes = useStyles({ error: error.titleError });
    const metricService = new MetricService();

    const toggleEdit = () => {
        setEditTitle(!editTitle);
        if (titleRef.current) {
            titleRef.current.focus();
        }
    };

    const errorChange = (key: string, value: boolean) => {
        setError((prevError) => ({
            ...prevError,
            [key]: value,
        }));
    };

    const handleDisabled = () => {
        if (loading) return true;
        if (
            !metric?.name ||
            !metric.description ||
            !category ||
            !metric.format ||
            !metric.frequency ||
            !metric.method ||
            !metric.timeFrame ||
            !metric.type
        )
            return true;
        if (metric.name === '' && metric.description === '') return true;
        return false;
    };

    const validateMetric = () => {
        const errors = {
            titleError: !metric?.name || metric.name.trim() === '',
            descriptionError: !metric?.description,
            categoryError: !category,
            formatError: !metric?.format,
            frequencyError: !metric?.frequency,
            methodError: !metric?.method,
            timeFrameError: !metric?.timeFrame,
            typeError: !metric?.type,
        };
        setError(errors);
        return Object.values(errors).some(Boolean);
    };

    const handleCreateOrUpdateMetric = async () => {
        if (!evaId) return;
        if (!metric) return;

        // Validate and set error states
        if (validateMetric()) {
            setLoading(false);
            return; // Exit if validation fails
        }

        setLoading(true);
        try {
            let newMetricId = '';
            const isNameExist = await metricService.isMetricNameUnique(metric.name, evaId, metricId);
            if (isNameExist) {
                errorChange('titleError', true);
                setLoading(false);
                return;
            }
            const metricData = {
                ...metric,
                category: category?.key || '',
                isEnabled: true,
            };
            if (mode === 'update' && metricId) {
                await metricService.updateMetric(metricData);
            } else {
                const newMetric = await metricService.createMetric(metricData, evaId);
                if (newMetric) newMetricId = newMetric.id;
            }

            updateMetric(mode === 'update' ? metricId || newMetricId : newMetricId, metricData, mode || 'create');
            setLoading(false);
            closeMetric();
        } catch (err) {
            console.error(err);
            // Optionally set an error state to display to the user
            setLoading(false);
        }
    };

    const { isLoading } = useQuery(
        ['getMetricDetails', metricId],
        async () => {
            try {
                const data = await metricService.getMetricDetails(metricId as string);
                setMetric(data);
                setCategory(categoryList.find((item) => item.key === data.category));
                return data; // Ensure to return the data
            } catch (e) {
                if (e instanceof Error) {
                    appInsights.trackException({ error: e });
                }
                return []; // Return an empty array in case of error
            }
        },
        { enabled: !!metricId && mode === 'update' },
    );

    if (isLoading) return <Loading />;

    return (
        <>
            <EvaDialog
                disabled={false}
                open={isOpenDeleteModal}
                setClose={setIsOpenDeleteModal}
                onConfirm={() => {
                    if (!metricId) return;
                    deleteMetric(metricId);
                    closeMetric();
                }}
                title={`Delete ${metric.name}`}
                content={
                    <div>
                        You're about to permanently delete this metric. This action cannot be undone. Are you sure you
                        want to proceed?
                    </div>
                }
                confirmButtonText='Delete Metric'
                buttonVariant='rejectRed'
            />
            <div className='w-full create-metric-container no-scrollbar'>
                <div className='w-full add-metric-title'>
                    <div className='w-full flex items-center space-x-4'>
                        <div className='cursor-pointer delete-metric'>
                            <div>
                                <BackIcon className='w-4 h-4' onClick={closeMetric} fill='rgba(111, 191, 225, 0.75)' />
                            </div>
                        </div>
                        {editTitle ? (
                            <div
                                className='w-full flex items-center'
                                ref={titleRef}
                                onBlur={() => {
                                    if (!metric?.name || metric.name.replaceAll(' ', '') === '') return;
                                    else setEditTitle(false);
                                }}
                                tabIndex={0}
                            >
                                <StyledTextField
                                    id='metric-title'
                                    className={`${classes.metricTitle} w-full`}
                                    font='small'
                                    fontWeight='medium'
                                    placeHolder=''
                                    textFieldPlaceholder='Metirc Name'
                                    value={metric?.name}
                                    setValue={(value) => setMetric((prev) => ({ ...prev, name: value }))}
                                    maxLength={maxLength}
                                    style={evaTitleFieldStyle}
                                    showRequiredStar={false}
                                    textColor='rgba(111, 191, 225, 1)'
                                    autoFocus
                                    setCount={setCount}
                                    onKeyDown={(e) => {
                                        errorChange('titleError', false);
                                        if (e.key === 'Enter') {
                                            toggleEdit();
                                        }
                                    }}
                                    onBlur={async () => {
                                        const nameExists = await metricService.isMetricNameUnique(
                                            metric?.name || '',
                                            evaId || '',
                                            metricId,
                                        );
                                        if (nameExists) {
                                            errorChange('titleError', true);
                                        }
                                    }}
                                />
                            </div>
                        ) : (
                            <div className='flex space-x-2 items-center justify-center group relative text-metric text-opacity-75 hover:text-opacity-100'>
                                <p
                                    className={clsx(
                                        'flex text-center font-medium relative',
                                        error.titleError && 'border-b border-red',
                                    )}
                                >
                                    <p>{metric?.name}</p>
                                </p>
                                <button className='edit-button group-hover:opacity-100 opacity-0' onClick={toggleEdit}>
                                    <div>
                                        <EditIcon className='w-2.5 h-2.5' fill='#6FBFE1' fillOpacity={0.75} />
                                    </div>
                                </button>
                            </div>
                        )}
                    </div>
                    {/* show counter */}
                    <div className='flex justify-end space-x-4 items-center'>
                        <div className='text-sm font-normal'>{maxLength - count}</div>
                        <button
                            className='delete-metric'
                            onClick={(e) => {
                                e.stopPropagation();
                                if (mode === 'update') setIsOpenDeleteModal(true);
                                else closeMetric();
                            }}
                        >
                            <div>
                                <DeleteIcon className='w-3 h-3' fill='rgba(111, 191, 225, 1)' fillOpacity={0.75} />
                            </div>
                        </button>
                    </div>
                </div>
                {error.titleError && <div className='text-error px-10'>Metric title already exists</div>}
                <div className='mt-5'>
                    <Description
                        id='metric-description'
                        placeholder='Provide the objectives of your new metric.'
                        maxLength={130}
                        showCounter
                        className='h-20 cursor-pointer'
                        background='bg-metric bg-opacity-10'
                        border={error.descriptionError ? 'border border-red' : 'border border-metric border-opacity-24'}
                        textColor='text-metric text-opacity-75'
                        value={metric?.description}
                        onChange={(e) => setMetric((prev) => ({ ...prev, description: e }))}
                    />
                </div>
                <div className='mt-2 space-y-2'>
                    <div>
                        <Select
                            id='metric-category'
                            entries={categoryList}
                            rounded='rounded-md'
                            placeholder='Category'
                            textColor='text-metric'
                            backgroundColor='bg-metric bg-opacity-10'
                            borderColor='metric-select-border'
                            iconColor='#6FBFE1BF'
                            optionsClassname='bg-metric-100 shadow-metric'
                            getName={(item) => item.name}
                            value={category}
                            onChange={(value) => {
                                setCategory(value);
                                setMetric((prev) => ({
                                    ...prev,
                                    category: value.key,
                                    format: '',
                                    method: '',
                                    type: '',
                                }));
                            }}
                            hoverColor='bg-metric bg-opacity-10'
                            marginTop={45}
                        />
                    </div>
                    <div>
                        <Select
                            id='metric-format'
                            entries={getFormatListByCategory(category?.name || '')}
                            rounded='rounded-md'
                            placeholder='Format'
                            textColor='text-metric'
                            backgroundColor='bg-metric bg-opacity-10'
                            borderColor='metric-select-border'
                            iconColor='#6FBFE1BF'
                            optionsClassname='bg-metric-100 shadow-metric'
                            value={metric?.format}
                            onChange={(value) =>
                                setMetric((prev) => ({ ...prev, format: value, method: '', type: '' }))
                            }
                            hoverColor='bg-metric bg-opacity-10'
                            marginTop={45}
                        />
                    </div>
                </div>
                <HorizontalSeparator className='mt-24 bg-metric bg-opacity-20' />
                <div className='metric-child-component'>
                    <p>Calculation Methods</p>
                    <div>
                        <Select
                            id='metric-frequency'
                            entries={getFrequencyList()}
                            rounded='rounded-md'
                            placeholder='Frequency'
                            textColor='text-metric'
                            backgroundColor='bg-metric bg-opacity-10'
                            borderColor='metric-select-border'
                            iconColor='#6FBFE1BF'
                            optionsClassname='bg-metric-100 shadow-metric'
                            value={metric?.frequency}
                            onChange={(value) => setMetric((prev) => ({ ...prev, frequency: value, timeFrame: '' }))}
                            hoverColor='bg-metric bg-opacity-10'
                            marginTop={45}
                        />
                    </div>
                    <div>
                        <Select
                            id='metric-method'
                            entries={getCalculationMethodList(metric?.format)}
                            rounded='rounded-md'
                            placeholder='Method'
                            textColor='text-metric'
                            backgroundColor='bg-metric bg-opacity-10'
                            borderColor='metric-select-border'
                            iconColor='#6FBFE1BF'
                            optionsClassname='bg-metric-100 shadow-metric'
                            value={metric?.method}
                            onChange={(value) => setMetric((prev) => ({ ...prev, method: value }))}
                            hoverColor='bg-metric bg-opacity-10'
                            marginTop={45}
                        />
                    </div>
                </div>
                <HorizontalSeparator className='mt-5 bg-metric bg-opacity-20' />
                <div className='metric-child-component'>
                    <p>Aggregation Rules</p>
                    <div>
                        <Select
                            id='metric-timeFrame'
                            entries={getTimeFrameList(metric.frequency)}
                            rounded='rounded-md'
                            placeholder='Time Frame'
                            textColor='text-metric'
                            backgroundColor='bg-metric bg-opacity-10'
                            borderColor='metric-select-border'
                            iconColor='#6FBFE1BF'
                            optionsClassname='bg-metric-100 shadow-metric'
                            value={metric?.timeFrame}
                            onChange={(value) => setMetric((prev) => ({ ...prev, timeFrame: value }))}
                            hoverColor='bg-metric bg-opacity-10'
                            marginTop={45}
                        />
                    </div>
                    <div>
                        <Select
                            id='metric-type'
                            entries={getAggregationTypeList(metric?.format)}
                            rounded='rounded-md'
                            placeholder='Type'
                            textColor='text-metric'
                            backgroundColor='bg-metric bg-opacity-10'
                            borderColor='metric-select-border'
                            iconColor='#6FBFE1BF'
                            optionsClassname='bg-metric-100 shadow-metric'
                            value={metric?.type}
                            onChange={(value) => setMetric((prev) => ({ ...prev, type: value }))}
                            hoverColor='bg-metric bg-opacity-10'
                            marginTop={45}
                        />
                    </div>
                </div>
                <HorizontalSeparator className='mt-5 bg-metric bg-opacity-20' />
                <div className='flex space-x-12 mt-5 items-center text-metric text-sm'>
                    <div className='font-medium'>Feedback</div>
                    <div className='flex space-x-4 font-normal'>
                        <div>Deactivate</div>
                        <Switch
                            checked={metric?.feedback}
                            onChange={(value) => setMetric((prev) => ({ ...prev, feedback: value }))}
                            uncheckedColor='bg-metric bg-opacity-25'
                            checkedColor='bg-metric bg-opacity-100'
                            uncheckedThumbColor='bg-metric'
                            checkedThumbColor='bg-metric-black'
                        />
                        <div>Activate</div>
                    </div>
                </div>
                <Button
                    className='w-full mt-5'
                    variant='metric'
                    onClick={handleCreateOrUpdateMetric}
                    disabled={handleDisabled()}
                >
                    {mode === 'create' ? 'Create' : 'Update'}
                </Button>
            </div>
        </>
    );
};

export default observer(CreateNewMetric);
