import { forwardRef, ComponentProps, ChangeEvent, useState, useEffect } from 'react';

import clsx from 'clsx';

import { ReactComponent as Lock } from '../../assets/icons/icon-lock.svg';

type InputVariant = 'standard' | 'amount' | 'website';

export type InputProps = {
    value?: string;
    variant?: InputVariant;
    required?: boolean;
    error?: boolean;
    type?: ComponentProps<'input'>['type'];
    placeholder?: string;
    onChange?: (value: string) => void;
    onChangeQuestion?: (text: string, index: number) => void;
    onBlur?: () => void;
    className?: string;
    left?: React.ReactNode;
    right?: React.ReactNode;
    inputProps?: ComponentProps<'input'>;
    containerRef?: React.Ref<HTMLDivElement>;
    label?: string;
    subLabel?: string;
    disabled?: boolean;
    step?: string;
    min?: string;
    containerClassName?: string;
    id?: string;
    maxLength?: number;
    handleValue?: (e: ChangeEvent<HTMLInputElement>) => ChangeEvent<HTMLInputElement>;
    pattern?: string;
    custom?: string;
    currentIndex?: number;
    lock?: boolean;
    border?: string;
    textColor?: string;
    rounded?: string;
    isPassword?: boolean;
    onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onClick?: () => void;
    background?: string;
};

export const Input = forwardRef<HTMLInputElement, InputProps>(
    (
        {
            value: realValue,
            onChange,
            onBlur,
            error,
            placeholder,
            type,
            required,
            left,
            right,
            className,
            inputProps,
            containerRef,
            containerClassName,
            label,
            disabled,
            variant,
            step,
            min,
            id,
            maxLength,
            handleValue,
            pattern,
            custom = false,
            onChangeQuestion,
            currentIndex,
            lock = false,
            border = '',
            textColor,
            rounded,
            isPassword = false,
            onKeyPress,
            onClick,
            background,
            subLabel,
        },
        ref,
    ) => {
        //added this because we couldn't delete the last char
        const [value, setValue] = useState(realValue);
        const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
            if (handleValue) e = handleValue(e);
            if (custom == 'text') {
                // If there is custom onChange event
                if (custom == 'text') {
                    const cursor = e.target.selectionStart;
                    const element = e.target;
                    window.requestAnimationFrame(() => {
                        element.selectionStart = cursor;
                        element.selectionEnd = cursor;
                    });
                    // @ts-ignore
                    onChangeQuestion(e.target.value, currentIndex);
                }
            } else if (onChange) {
                if (!e.target.value) {
                    onChange('');
                    setValue('');
                }
                if (!isPassword) {
                    setValue(e.target.value);
                    onChange(e.target.value);
                } else if (isPassword) {
                    const value = e.target.value.replace(/\s/g, ''); // Remove spaces
                    setValue(value);
                    onChange(value);
                }
            }
        };
        useEffect(() => {
            setValue(realValue);
        }, [realValue]);
        return (
            <div className={clsx('text-sm flex-grow relative', containerClassName)}>
                {label && (
                    <div className='flex mb-2 justify-between'>
                        <label className='font-medium text-sm'>
                            <span className='text-darker text-opacity-75'>{label}</span>
                            {required && <span className='text-error'>*</span>}
                            <span className='text-xs text-darker text-opacity-50 ml-2 font-normal'>{subLabel}</span>
                        </label>
                        {lock && <Lock className='w-3 h-3' />}
                    </div>
                )}
                <div
                    ref={containerRef}
                    className={clsx(
                        'flex items-center transition-shadow  overflow-hidden h-10',
                        border ? border : 'border border-blue-dark border-opacity-10 hover:border-opacity-20',
                        disabled ? 'bg-secondary-100 text-neutral-400' : background ? background : 'bg-white',
                        error && 'ring-error-900 ring-2',
                        className?.indexOf('w-') !== -1 ? className : className + ' w-full',
                        border ? border : 'border',
                        rounded ? rounded : 'rounded-full',
                    )}
                >
                    {left}
                    <input
                        pattern={pattern}
                        maxLength={maxLength}
                        id={id}
                        ref={ref}
                        {...inputProps}
                        type={type}
                        onBlur={onBlur}
                        placeholder={placeholder}
                        required={required}
                        className={clsx(
                            `focus:outline-none flex-grow px-4 w-full h-10`,
                            background && 'bg-transparent',
                            variant && variant === 'amount' ? 'rounded-l-lg' : 'rounded-lg',
                            variant && variant === 'website' ? 'pl-20' : 'pl-4',
                            inputProps?.className,
                            'disabled:text-neutral-400',
                            disabled && 'cursor-not-allowed',
                            textColor ? textColor : 'text-neutral-700',
                            rounded ? rounded : '',
                        )}
                        value={value}
                        onChange={(e) => handleChange(e)}
                        onClick={() => onClick && onClick()}
                        onKeyPress={(e) => onKeyPress && onKeyPress(e)}
                        disabled={disabled}
                        step={step}
                        min={min}
                    />
                    {variant === 'website' && (
                        <div
                            className='absolute border-r border-blue-dark border-opacity-10 pr-2 pl-4
                            font-normal text-darker opacity-75 h-full flex items-center cursor-default'
                        >
                            https://
                        </div>
                    )}
                    {right}
                </div>
            </div>
        );
    },
);
