import { Link } from 'react-router-dom';

export type ResetPasswordCardProps = {
    email: string;
};

export const ResetPasswordCard = ({ email }: ResetPasswordCardProps) => {
    return (
        <div className='flex flex-col items-center mx-auto max-w-md w-full px-1'>
            <div className='w-full'>
                <h1 className='text-tiny md:text-base font-semibold text-darker text-opacity-75 mb-6 text-center md:text-left'>
                    Reset your password
                </h1>
                <div className='text-sm text-darker text-opacity-75'>
                    <p>
                        We have sent a reset password email to <span className='font-medium'> {email}</span>
                    </p>
                    <p className='mb-4'>Please click the reset password link to set your new password.</p>
                    <p className='font-medium'>Didn't receive the email yet?</p>
                    <p>
                        Please check your spam folder, or
                        <Link to={`/sign-in/forgot-password`} className='text-bberry-blue font-medium  '>
                            {' '}
                            resend
                        </Link>{' '}
                        the email.
                    </p>
                </div>
            </div>
            <div className='flex items-center justify-center space-x-2 text-sm font-medium mt-12'>
                <Link to={'/sign-in'} className='text-bberry-blue my-2 underline'>
                    Back to sign-in
                </Link>
            </div>
        </div>
    );
};
