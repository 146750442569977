import { appInsights } from '../../AppInsights';
import { Metric } from '../../components/organisation/copilotOrganisation/eva/components/metrics/metricsType';
import { del, get, post, put } from '../../utils/network';

export class MetricService {
    createMetric(metric: Metric, evaId: string) {
        try {
            return post(`/metrics/create`, { evaId, metric });
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }

    isMetricNameUnique(name: string, evaId: string, metricId?: string) {
        try {
            return post(`/metrics/name-exists`, { evaId, name, metricId });
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }

    getAllMetrics(evaId: string) {
        try {
            return get(`/metrics/${evaId}`);
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }

    enableOrDisableFavourite(metricId: string, status: boolean) {
        try {
            return put(`/metrics/favourite`, { metricId, status });
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }

    enableOrDisableMetric(metricId: string, status: boolean) {
        try {
            return put(`/metrics/enable-disable`, { metricId, status });
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }

    enableOrDisableLock(metricId: string, status: boolean) {
        try {
            return put(`/metrics/lock`, { metricId, status });
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }

    deleteMetric(metricId: string) {
        try {
            return del(`/metrics/${metricId}`);
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }

    addMetricFeedback(metricId: string, feedback: string) {
        try {
            return post(`/metrics/add-feedback`, { metricId, feedback });
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }

    deleteMetricFeedback(metricId: string) {
        try {
            return post(`/metrics/delete-feedback`, { metricId });
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }

    getMetricDetails(metricId: string) {
        try {
            return get(`/metrics/details/${metricId}`);
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }

    updateMetric(metric: Metric) {
        try {
            return put(`/metrics/update`, { metric });
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }

    generateMetricProtocol(metricId: string, action: string, protocolName: string) {
        try {
            return post(`/metrics/protocol`, { metricId, action, protocolName });
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }

    updateProtocolStatus(metricId: string, protocolName: string) {
        try {
            return put(`/metrics/${metricId}/protocol`, { metricId, protocolName });
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }

    updateProtocolStatusOnDelete(evaId: string, protocolName: string) {
        try {
            return put(`/metrics/protocol-delete`, { evaId, protocolName });
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }

    getMetricData(id: string, format?: string[], search?: string) {
        try {
            return get(`/metrics/${id}/metric-list?format=${format}&search=${search}`);
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }

    getMetricProtocols(id: string) {
        try {
            return get(`/metrics/${id}/protocols`);
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }
}
