import { useState } from 'react';

import dayjs from 'dayjs';
import _ from 'lodash';

import { appInsights } from '../AppInsights';
import { useServices } from '../components/ServiceProvider';
import { useStore } from '../store';

export const useSession = () => {
    const { session } = useServices();
    const store = useStore();
    const [loading, setLoading] = useState<boolean>(false);

    const getAllSessions = async () => {
        try {
            const sessions = await session.getAll();
            store.session.init(sessions);
            return sessions;
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
            return [];
        }
    };

    const groupSessionsByDomain = async () => {
        setLoading(true);
        try {
            const sessions = await getAllSessions();
            if (sessions.length) {
                const groupedByDomain = _.groupBy(sessions, 'domain');
                const mappedData = _.map(groupedByDomain, (value, key) => ({
                    domain: key,
                    data: value,
                }));
                setTimeout(() => {
                    setLoading(false);
                }, 3000);
                return mappedData;
            } else {
                setLoading(false);
                return [];
            }
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
            setLoading(false);
            return [];
        }
    };

    const getSessionsByDate = async (spaceId: string) => {
        try {
            const sessions = await session.getSessionsBySpaceId(spaceId);
            if (sessions) {
                const groupedByDomain = _.groupBy(sessions, (item) => dayjs(item.date).format('YYYY-MM-DD'));
                const mappedData = _.map(groupedByDomain, (value, key) => ({
                    date: key,
                    data: value,
                }));
                setTimeout(() => {
                    setLoading(false);
                }, 3000);
                return mappedData;
            } else return [];
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
            return [];
        }
    };

    async function deleteSession(sessionId: number) {
        try {
            const sessions = await session.deleteSession(sessionId);
            return sessions;
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
            console.log(err);
        }
    }

    async function deleteSessionsByDomain(domain: string) {
        try {
            const sessions = await session.deleteSessionsByDomain(domain);
            return sessions;
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
            console.log(err);
        }
    }

    return {
        groupSessionsByDomain,
        loading,
        getSessionsByDate,
        deleteSession,
        deleteSessionsByDomain,
    };
};
