import { useEffect } from 'react';

import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';

import { ReactComponent as ArrowIcon } from '../../../assets/icons/icon-arrow-down-grey.svg';
import { ReactComponent as UKIcon } from '../../../assets/icons/icon-uk.svg';
import { useOrganisationForm } from '../../../hooks/useOrganisation';
import { Organisation } from '../../../store/OrganisationStore';
import { countryList } from '../../../store/UserStore';
import { businessTypes } from '../../../utils/constants';
import { Button } from '../../basic/Button.component';
import HorizontalSeparator from '../../basic/HorizontalSeparator';
import { IconAlert } from '../../basic/IconAlert.component';
import FormInput from '../../profile/Form/FormInput';
import FormSelect from '../../profile/Form/FormSelect';

const OrganistaionForm = ({
    settings = false,
    initialValues,
    file,
}: {
    settings?: boolean;
    initialValues?: Organisation | null;
    file?: File | null;
}) => {
    const {
        fields,
        error,
        isLoading,
        updateOrganisation,
        createOrganisation,
        success,
        emailVerified,
        isValid,
        setError,
    } = useOrganisationForm(settings, initialValues);
    const { id: organisationId } = useParams<{ id: string }>();

    useEffect(() => {
        // Check if all fields are valid whenever fields change
        if (isValid() && error === 'Some fields are not valid, please check again') {
            setError(''); // Clear error message
        }
    }, [fields, isValid, setError, error]);

    return (
        <div className='w-full'>
            {settings && (
                <div className='grid grid-cols-2 gap-6 mb-6'>
                    <FormInput
                        {...fields.email}
                        rounded='rounded'
                        label='Email'
                        subLabel='(used for billing)'
                        placeholder='email'
                        edit
                        className='w-full'
                        required
                        onKeyPress={() => {
                            if (isValid()) setError('');
                        }}
                    />
                </div>
            )}
            <div className='grid grid-cols-2 gap-6'>
                <FormInput
                    {...fields.organisationName}
                    rounded='rounded'
                    label='Organisation Name'
                    placeholder='Organisation Name'
                    edit
                    className='w-full'
                    required
                    maxLength={50}
                />
                <FormSelect
                    entries={Object.values(businessTypes)}
                    {...fields.businessType}
                    rounded='rounded'
                    label='Organisation Type'
                    placeholder='Organisation Type'
                    edit
                    className='w-full'
                    required
                    error={fields.businessType.error}
                    value={fields.businessType.value}
                />
                <FormInput
                    {...fields.crn}
                    rounded='rounded'
                    label='Organisation Registration (CRN)'
                    placeholder='Organisation Registration'
                    edit
                    className='w-full'
                />
                <FormInput
                    {...fields.vat}
                    rounded='rounded'
                    label='VAT number'
                    placeholder='VAT Number (optional)'
                    edit
                    className='w-full'
                />
            </div>
            <HorizontalSeparator className='my-6' />
            <div className='grid grid-cols-2 gap-6'>
                <FormInput
                    {...fields.phone}
                    rounded='rounded'
                    label='Contact Phone Number'
                    placeholder='Phone Number'
                    edit
                    className='w-full'
                    maxLength={10}
                    left={
                        <div
                            className='h-full w-12 border-r border-blue-dark border-opacity-10
                                flex items-center justify-center'
                        >
                            <button className='flex items-center space-x-1 ml-1'>
                                <UKIcon width={11} height={8} />
                                <ArrowIcon width={8} height={4} fill='#0F1044' fillOpacity={75} />
                            </button>
                        </div>
                    }
                />
                <FormInput
                    {...fields.website}
                    rounded='rounded'
                    label='Website'
                    placeholder='Organisation Website'
                    edit
                    className='w-full'
                />
                <FormInput
                    {...fields.postcode}
                    rounded='rounded'
                    label='Postcode'
                    placeholder='Organisation Postcode'
                    edit
                    className='w-full'
                    required
                    maxLength={8}
                />
                <FormInput
                    {...fields.address}
                    rounded='rounded'
                    label='Address'
                    placeholder='Organisation Address'
                    edit
                    className='w-full'
                    required
                />
                <FormInput
                    {...fields.city}
                    rounded='rounded'
                    label='Town/City'
                    placeholder='Town/City'
                    edit
                    className='w-full'
                    required
                />
                <FormSelect
                    entries={countryList}
                    {...fields.country}
                    rounded='rounded'
                    label='Country/Region'
                    placeholder='Country/Region'
                    edit
                    className='w-full'
                    required
                    value='United Kingdom'
                />
            </div>

            {error && (
                <div className='mt-6'>
                    <IconAlert message={error} />
                </div>
            )}
            {success && (
                <div className='mt-6'>
                    <IconAlert message={success} type='success' />
                </div>
            )}
            {!settings ? (
                <div className='flex flex-col'>
                    <Button
                        disabled={isLoading || !isValid()}
                        onClick={() => {
                            createOrganisation(organisationId, file);
                            localStorage.setItem('organisationName', fields.organisationName.value);
                        }}
                        className='flex space-x-2 w-full py-5 h-10 items-center mt-5'
                        variant='primaryDarkBlue'
                        size='oval'
                        type='submit'
                    >
                        <p>Next</p>
                    </Button>
                </div>
            ) : (
                <Button
                    disabled={isLoading || !isValid()}
                    onClick={() => updateOrganisation(organisationId, file, Number(initialValues?.logo))}
                    className='flex space-x-2 w-full mt-5 py-5 h-10 items-center'
                    variant='bberryBlue'
                    size='oval'
                    type='submit'
                >
                    <p>Save</p>
                </Button>
            )}
            {settings && ((initialValues?.emailVerified === false && initialValues?.email) || !emailVerified) && (
                <div className='mt-2 flex justify-center'>
                    <p className='text-sm italic text-darker text-opacity-75'>Verify your email address</p>
                </div>
            )}
        </div>
    );
};

export default observer(OrganistaionForm);
