import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface EvaState {
    isCreateEvaFromBlueprints: boolean;
}

const initialState: EvaState = {
    isCreateEvaFromBlueprints: false,
};

const EvaInfo = createSlice({
    name: 'EvaInfo',
    initialState,
    reducers: {
        SetIsCreateEvaFromBlueprints: (state, action: PayloadAction<boolean>) => {
            state.isCreateEvaFromBlueprints = action.payload;
        },
    },
});

export const { SetIsCreateEvaFromBlueprints } = EvaInfo.actions;

export default EvaInfo.reducer;
